import React from 'react';
import MarkdownIt from 'markdown-it';

import Prism from 'prismjs';
import 'prismjs/themes/prism-tomorrow.css'; // Or any other theme you like
import { CodeBlock } from 'markdown-ui'; // Optional, for styling

function MarkdownRender(props) {
    const { images, markdown } = props; // Destructuring for cleaner access
    return (
        <div dangerouslySetInnerHTML={{__html: renderMarkdown(images, markdown)}}/>
    );
}

export default MarkdownRender;

// Convert Markdown to HTML
function replaceImageNames(imagePaths, htmlString) {
    const regex = /<img[^>]*src="([^"]+)"[^>]*>/g;

    return htmlString.replace(regex, (match, capturedSrc) => {
        const newSrc = imagePaths[capturedSrc];
        return `<img src="${newSrc}" alt="" style="max-width:100%"/>`;
    });
}

function replaceCenterContent(text) {
    const regex = /\{\{center start\}\}([\s\S]*?)\{\{center end\}\}/gm; // Multiline flag 'm' added

    return text.replace(regex, (match, capturedContent) => {
        return `<div style="text-align: center">${capturedContent}</div>`;
    });
}

function renderMarkdown(imagePaths, markdownContent) {

    var hljs = require('highlight.js') // https://highlightjs.org/

// Actual default values
    var md = require('markdown-it')({
        highlight: function (str, lang) {
            if (lang && hljs.getLanguage(lang)) {
                try {
                    return '<pre><code class="hljs">' +
                        hljs.highlight(str, { language: lang, ignoreIllegals: true }).value +
                        '</code></pre>';
                } catch (__) {}
            }

            return '<pre><code class="hljs">' + md.utils.escapeHtml(str) + '</code></pre>';
        }
    });
    let data = md.render(markdownContent);
    data = replaceImageNames(imagePaths, data);
    data = replaceCenterContent(data);
    return data;
}