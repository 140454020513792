import React from 'react';
import ServiceNavigation from '../../../../ServiceNavigation';
import {
    AppLayout,
    Button,
    ColumnLayout,
    FormField,
    Icon,
    Select,
    Container,
    Header,
    Box,
    Grid,
    SpaceBetween,
    Link
} from '../../../../../aws-ui-components';
import '../../../../../styles/servicehomepage.scss';

import MarkdownRender from "../../../../utils/MarkdownRender";
import '../../../../portal-style.scss';

// Component ServiceHomepage is a skeleton of a service's homepage using AWS-UI React components.
export default () => {
    return (
        <AppLayout
            disableContentPaddings={true}
            navigation={<ServiceNavigation />} // Navigation panel content imported from './ServiceNavigation.jsx'
            content={<Content />}
            contentType="default"
            navigationOpen={true}
            toolsHide={true}
        />
    );
};

// The content in the main content area of the App layout
const Content = () => (
    <Box padding="s" margin={{bottom: 'l'}}>
        <h1>Route 53 DOCtor: Control Plane: Customer API Example</h1>
        <h4>Last Updated: April 19th, 2024</h4>

        <Box padding={{top: 'l'}}>
            <Grid
                gridDefinition={[
                    {colspan: {xl:8, s:10, xxs: 12}, offset: {xl:2, s: 1, xxs: 0}},
                ]}
            >
                <SpaceBetween size="l">
                    <Container>
                        <MarkdownRender images={imagePaths} markdown={markdown} />
                    </Container>
                </SpaceBetween>
            </Grid>
        </Box>
    </Box>
);


// The Images
import myImage from "../../../../../data/images/logo512.png"




const imagePaths = {
    myImage: myImage, // Assuming the image component name matches the file name (optional)
};

const markdown = `
# Introduction
So you wanna know to communicate with the the Route 53 Customer API using Java Huh?  Don't worry, we got you!  Here we will go into depth what is required to to easily setup a simple application to communicate with our Customer API!  Should you have additional questions please feel free to reach out in slack channel [#r53-doctor-interest](https://amazon.enterprise.slack.com/archives/C04E2TFM87N), or to your specific interest channel.

It should also be pointed out that within your **Onboarding Package** you received when first Onboarding, there is an example within it that has the basic functionality to communicate with the Customer API.  This page is for convenience and will be using the [HelloWorld-DOC](https://code.amazon.com/packages/R53DrHelloWorld-DOC/trees/mainline) package

# Setting up your 'Config'
With in the Config file, the important items to point out would be the **RIP Dependency**, **Coral Dependency**, and the **DOCtor Dependencies**
\`\`\`perl
# -*-perl-*-

package.R53DrHelloWorld-DOC = {
    ...

    dependencies = {
        1.0 = {
            ...
            # RIP Dependencies
            RIPDataAllSQLite = 1.0;
            RIPDataAllJavaResources = 1.0;
            RIPJavaHelperAdapter = 2.0;
            RIPJavaHelper = 2.0;

            # Coral Dependency
            MidwayAuthJavaAwsCredentialsProvider = 1.1;
            CoralRetry = 2.0;
            CoralClientBuilder = 1.1;

            # DOCtor Dependencies
            R53DrCustomerAPIClientConfig = 1.0;
            R53DrCustomerAPIJavaClient = 1.0;
            R53DrCustomerAPIModel = 1.0;
            ...
        };
    };

    runtime-dependencies = {
        1.0 = {
            JDK17 = 1.0;
            log4j = 2.14;
            CoralAwsClientSigners = 1.1;
            CoralRpcSupport = 1.1;
            CoralClientHttp = 1.1;
        };
    };
    
    resolves-conflict-dependencies = {
        1.0 = {
            log4j = 2.14;
            Log4j-core = 2.x;
            Log4j-1_2-api = 2.x;

            Jackson-annotations = 2.12.x;
            Jackson-core = 2.12.x;
            Jackson-databind = 2.12.x;
            RIPJavaHelper = 2.0;
            JMESPathJava = 1.12.x;
        };
    };
    
    ...
};

\`\`\`

# The Application 
Here is the code, so you don't have to go track it down.  T
\`\`\`java
    /**
     * Setup The Customer API
     * @param rip_service - The DOCtor RIP Service
     * @param stage - Stage, (Beta, Gamma, Perf, Prod)
     * @param aws_account - AWS Account
     * @param console_role - Console Role
     * @return - Customer API
     */
    private static R53DrCustomerAPIClient getCustomerAPI(IService rip_service, String stage, String aws_account, String console_role) {
        // Setup the Credentials
        AWSCredentialsProvider credentialsProvider = new MidwayAuthAwsCredentialsProviderFactory().getCredentialsProvider(aws_account, console_role);

        // Now we need to communicate with coral, with our generic retryStrategy
        RetryStrategy<Void> retryStrategy = (new ExponentialBackoffAndJitterBuilder()).retryOn(Calls.getCommonRecoverableThrowables()).withInitialIntervalMillis(500.0D).withMaxAttempts(3).withExponentialFactor(2.0D).build();

        // setup the client
        return CoralClientFactory.newInstance(R53DrCustomerAPIClient.class, rip_service.endpoints().get(stage).additionalProperties().get("qualifier"), credentialsProvider, retryStrategy);
    }

    /**
     * This will get the backup regions from RIP, and you can specify the number of backup regions you want.  This will
     * take the list, and randomly return the specified number of regions
     * @param rip_service - DOCtor RIP Service
     * @param stage - Stage, (Beta, Gamma, Perf, Prod)
     * @param max_number_backup_regions - The Number of Backup Regions you want
     * @return - A List of Backup Regions
     */
    private static List<String> getBackupRegions(IService rip_service, String stage, int max_number_backup_regions) {
        if(rip_service.endpoints().get(stage).additionalProperties().containsKey("recommend_backup_regions")) {
            List<String> recommendBackupRegions = new ArrayList<>(Arrays.stream(rip_service.endpoints().get(stage).additionalProperties().get("recommend_backup_regions").split(",")).toList());
            Collections.shuffle(recommendBackupRegions);
            if(max_number_backup_regions >= recommendBackupRegions.size()) {
                return ImmutableList.copyOf(recommendBackupRegions);
            } else {
                return ImmutableList.copyOf(recommendBackupRegions.subList(0, max_number_backup_regions));
            }
        }
        return ImmutableList.of();
    }

    /**
     * Creates a DOCtet
     * @param customerAPIClient - A client to the customer api
     * @param rip_service - The DOCtor RIP Service
     * @param stage - Stage, (Beta, Gamma, Perf, Prod)
     * @param max_number_backup_regions - the number of backup regions you want to use
     * @param caller_reference - theses are the backup regions where you would like to propagate your data. It can be either airport
     *                         codes or region names but it cannot be left blank AND it cannot be part of the region we are calling.
     *                         Meaning, because this example is setup to use "PDX", PDX isn't included as a backup region
     * @param insufficient_timeout - seconds before sending the default value
     * @param insufficient_value - the default value that will be sent once the timeout has been reached
     * @return - Doctet Response containing, an ID, and ARN
     */
    private static CreateDoctetResponse createDoctet(R53DrCustomerAPIClient customerAPIClient,
                                                     IService rip_service,
                                                     String stage,
                                                     Integer max_number_backup_regions,
                                                     String caller_reference,
                                                     int insufficient_timeout,
                                                     String insufficient_value) {
        return customerAPIClient.callCreateDoctet(new CreateDoctetRequest.Builder()
                .withCallerReference(caller_reference)
                .withInsufficientDataTimeout(insufficient_timeout)
                .withInsufficientDataValue(insufficient_value)
                .withBackupRegions(getBackupRegions(rip_service, stage, max_number_backup_regions))
                .build());
    }

    /**
     * Updates a doctet
     * @param customerAPIClient - A client to the customer api
     * @param id - the ID or ARN of the DOCtet ID
     * @param insufficient_timeout - seconds before sending the default value
     * @param insufficient_value - the default value that will be sent once the timeout has been reached
     */
    private static void updateDoctetById(R53DrCustomerAPIClient customerAPIClient,
                                         String id,
                                         int insufficient_timeout,
                                         String insufficient_value) {
        customerAPIClient.callUpdateDoctet(new UpdateDoctetRequest.Builder()
                .withInsufficientDataTimeout(insufficient_timeout)
                .withInsufficientDataValue(insufficient_value)
                .withId(id)
                .build());
    }

    /**
     * Updates a Doctet
     * @param customerAPIClient - A client to the customer api
     * @param caller_reference - the Caller Reference created with the DOCtet
     * @param insufficient_timeout - seconds before sending the default value
     * @param insufficient_value - the default value that will be sent once the timeout has been reached
     */
    private static void updateDoctetByCallerReference(R53DrCustomerAPIClient customerAPIClient,
                                                      String caller_reference,
                                                      int insufficient_timeout,
                                                      String insufficient_value) {
        customerAPIClient.callUpdateDoctetByCallerReference(new UpdateDoctetByCallerReferenceRequest.Builder()
                .withInsufficientDataTimeout(insufficient_timeout)
                .withInsufficientDataValue(insufficient_value)
                .withCallerReference(caller_reference)
                .build());
    }

    /**
     * Deletes a doctet
     * @param customerAPIClient - A client to the customer api
     * @param id - the ID or ARN of the DOCtet ID
     */
    private static void deleteDoctetById(R53DrCustomerAPIClient customerAPIClient, String id) {
        customerAPIClient.callDeleteDoctet(new DeleteDoctetRequest.Builder().withId(id).build());
    }

    /**
     * Deletes a Doctet
     * @param customerAPIClient - A client to the customer api
     * @param caller_reference - the Caller Reference created with the DOCtet
     */
    private static void deleteDoctetByCallerReference(R53DrCustomerAPIClient customerAPIClient, String caller_reference) {
        customerAPIClient.callDeleteDoctetByCallerReference(new DeleteDoctetByCallerReferenceRequest.Builder().withCallerReference(caller_reference).build());
    }

    /**
     * Gets a Doctet
     * @param customerAPIClient - A client to the customer api
     * @param id - the ID or ARN of the DOCtet ID
     * @return - DoctetRecord
     */
    private static DoctetRecord getDoctetById(R53DrCustomerAPIClient customerAPIClient, String id) {
        return customerAPIClient.callGetDoctet(new GetDoctetRequest.Builder().withId(id).build()).getDoctet();
    }

    /**
     * Gets a Doctet
     * @param customerAPIClient - A client to the customer api
     * @param caller_reference - the Caller Reference created with the DOCtet
     * @return - DoctetRecord
     */
    private static DoctetRecord getDoctetByCallerReference(R53DrCustomerAPIClient customerAPIClient, String caller_reference) {
        return customerAPIClient.callGetDoctetByCallerReference(new GetDoctetByCallerReferenceRequest.Builder().withCallerReference(caller_reference).build()).getDoctet();
    }

    public static void main(String[] args) throws IOException, InterruptedException {

        // setup RIP
        IRIPHelper rip_helper = ... // Setup Rip

        // ----------------------------
        //          VARIABLES!
        // ----------------------------
        // Where do you want your doctet
        final String stage = "beta"; <-- Change This
        final String airport = "pdx"; <-- Change This
        // Who do you want to own your doctet
        final String aws_account_id = "012345678900";  <-- Change This // Make sure you use an account with a Service Principle that is onboarded with DOCtor
        final String console_role = "Admin";

        // some setting for your doctet
        final Integer max_number_backup_regions = 2;

        // --------------------------------------------------------
        //          The Demo, shouldn't modify below here!
        // --------------------------------------------------------

        // setup connection to information from RIP
        IService rip_service = rip_helper.region(airport).service("r53-doctor");
        R53DrCustomerAPIClient customerAPIClient = getCustomerAPI(rip_service, stage, aws_account_id, console_role);

        // using doctet ID
        {
            System.out.println("-----------------------------------------");
            System.out.println("------------ Using DOCtet ID ------------");
            System.out.println("-----------------------------------------");
            String doctet_id = createDoctet(customerAPIClient,
                    rip_service,
                    stage,
                    max_number_backup_regions,
                    UUID.randomUUID().toString(),
                    60,
                    "LAST_KNOWN_STATE").getId();
            System.out.println("🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉");
            System.out.println(" DOCTET CREATED !!!: " + doctet_id);
            System.out.println("🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉");
            DoctetRecord was_doctet = getDoctetById(customerAPIClient, doctet_id);
            updateDoctetById(customerAPIClient, doctet_id, 70, "LAST_KNOWN_STATE");
            DoctetRecord now_doctet = getDoctetById(customerAPIClient, doctet_id);
            System.out.println("🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇");
            System.out.println(" DOCTET UPDATED!!!: timeout: " + was_doctet.getInsufficientDataTimeout() + " -> " + now_doctet.getInsufficientDataTimeout());
            System.out.println("🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇");
            deleteDoctetById(customerAPIClient, doctet_id);
            System.out.println("🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️");
            System.out.println(" DOCTET DELETED!!!: id: " + doctet_id);
            System.out.println("🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️");
        }

        // using doctet ARN
        {
            System.out.println("-----------------------------------------");
            System.out.println("------------ Using DOCtet ARN ------------");
            System.out.println("-----------------------------------------");
            String doctet_arn = createDoctet(customerAPIClient,
                    rip_service,
                    stage,
                    max_number_backup_regions,
                    UUID.randomUUID().toString(),
                    60,
                    "LAST_KNOWN_STATE").getArn();
            System.out.println("🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉");
            System.out.println(" DOCTET CREATED !!!: " + doctet_arn);
            System.out.println("🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉");
            DoctetRecord was_doctet = getDoctetById(customerAPIClient, doctet_arn);
            updateDoctetById(customerAPIClient, doctet_arn, 70, "LAST_KNOWN_STATE");
            DoctetRecord now_doctet = getDoctetById(customerAPIClient, doctet_arn);
            System.out.println("🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇");
            System.out.println(" DOCTET UPDATED!!!: timeout: " + was_doctet.getInsufficientDataTimeout() + " -> " + now_doctet.getInsufficientDataTimeout());
            System.out.println("🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇");
            deleteDoctetById(customerAPIClient, doctet_arn);
            System.out.println("🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️");
            System.out.println(" DOCTET DELETED!!!: arn: " + doctet_arn);
            System.out.println("🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️");
        }

        // using Caller Reference
        {
            System.out.println("-------------------------------------------------------");
            System.out.println("------------ Using DOCtet Caller Reference ------------");
            System.out.println("-------------------------------------------------------");
            final String caller_reference = UUID.randomUUID().toString();
            createDoctet(customerAPIClient,
                    rip_service,
                    stage,
                    max_number_backup_regions,
                    caller_reference,
                    60,
                    "LAST_KNOWN_STATE");
            System.out.println("🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉");
            System.out.println(" DOCTET CREATED !!!: " + caller_reference);
            System.out.println("🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉");
            DoctetRecord was_doctet = getDoctetByCallerReference(customerAPIClient, caller_reference);
            updateDoctetByCallerReference(customerAPIClient, caller_reference, 70, "LAST_KNOWN_STATE");
            DoctetRecord now_doctet = getDoctetByCallerReference(customerAPIClient, caller_reference);
            System.out.println("🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇");
            System.out.println(" DOCTET UPDATED!!!: timeout: " + was_doctet.getInsufficientDataTimeout() + " -> " + now_doctet.getInsufficientDataTimeout());
            System.out.println("🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇");
            deleteDoctetByCallerReference(customerAPIClient, caller_reference);
            System.out.println("🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️");
            System.out.println(" DOCTET DELETED!!!: caller_reference: " + caller_reference);
            System.out.println("🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️");
        }
    }
}

\`\`\`
# The Output

\`\`\`shell
-----------------------------------------
------------ Using DOCtet ID ------------
-----------------------------------------
🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉
 DOCTET CREATED !!!: 218ef88c-a2bc-6202-38b1-4400219c75a3
🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉
🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇
 DOCTET UPDATED!!!: timeout: 60 -> 70
🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇
🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️
 DOCTET DELETED!!!: id: 218ef88c-a2bc-6202-38b1-4400219c75a3
🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️
-----------------------------------------
------------ Using DOCtet ARN ------------
-----------------------------------------
🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉
 DOCTET CREATED !!!: arn:aws:r53doctor:us-west-2:377843999866:doctet/dd8ef88c-a5ea-6202-6bf5-980004a69b9e
🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉
🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇
 DOCTET UPDATED!!!: timeout: 60 -> 70
🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇
🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️
 DOCTET DELETED!!!: arn: arn:aws:r53doctor:us-west-2:377843999866:doctet/dd8ef88c-a5ea-6202-6bf5-980004a69b9e
🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️
-------------------------------------------------------
------------ Using DOCtet Caller Reference ------------
-------------------------------------------------------
🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉
 DOCTET CREATED !!!: 0f7b74e8-f2dc-4cc0-8e90-6c54dd78e105
🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉
🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇
 DOCTET UPDATED!!!: timeout: 60 -> 70
🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇🏇
🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️
 DOCTET DELETED!!!: caller_reference: 0f7b74e8-f2dc-4cc0-8e90-6c54dd78e105
🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️🗑️

\`\`\`

`