import React from 'react';
import ServiceNavigation from '../../ServiceNavigation';
import {
    AppLayout,
    Button,
    ColumnLayout,
    FormField,
    Icon,
    Select,
    Container,
    Header,
    Box,
    Grid,
    SpaceBetween,
    Link
} from '../../../aws-ui-components';
import '../../../styles/servicehomepage.scss';

import MarkdownRender from "../../utils/MarkdownRender";

// Component ServiceHomepage is a skeleton of a service's homepage using AWS-UI React components.
export default () => {
    return (
        <AppLayout
            disableContentPaddings={true}
            navigation={<ServiceNavigation />} // Navigation panel content imported from './ServiceNavigation.jsx'
            content={<Content />}
            contentType="default"
            navigationOpen={true}
            toolsHide={true}
        />
    );
};

// The content in the main content area of the App layout
const Content = () => (
    <Box padding="s" margin={{bottom: 'l'}}>
        <h1>Infrastructure</h1>
        <h4>Last Updated: April 19th, 2024</h4>

        <Box padding={{top: 'l'}}>
            <Grid
                gridDefinition={[
                    {colspan: {l:8, s:10, xxs: 12}, offset: {l:2, s: 1, xxs: 0}},
                ]}
            >
                <SpaceBetween size="l">
                    <Container>
                        <MarkdownRender images={imagePaths} markdown={markdown} />
                    </Container>
                </SpaceBetween>
            </Grid>
        </Box>
    </Box>
);


// The Images
import myImage from "../../../data/images/logo512.png"
const imagePaths = {
    myImage: myImage, // Assuming the image component name matches the file name (optional)
};

const markdown = `
# How Infrastructure Deployment’s Occur 

Inside this section we are going to be looking at the Infrastructure Pipeline for a service component.  All of our pipelines within our system is utilizing a modified version of the BONES Framework.  Each service component has two pipelines an Infrastructure Pipeline and a Code Pipeline.  An Infrastructure Pipeline has five different CloudFormation stacks it maintains: Singlepass, VPC, Bastion, Service Infrastructure, and Alarms.  Deployments though theses pipelines happen in parallel to all stack following the pipeline’s wave distribution, however, promotions are done Stack by Stack.  For example, when a region’s Singlepass stack deploys and it’s approval workflow complete it continues the deployment to the next wave’s Singlepass stack.  Stacks do not wait for other stacks to complete, meaning SinglePass and VPC within a wave deploy in parallel.  Deployments though the Infrastructure pipeline are restricted by using Time Windows and Promotion Blockers controlled by Cloud Watch Alarms.

## Single Pass
The Singlepass stack sets up the S3 bucket and IAM Roles and Policies that will allow the SPIE Service to push credentials and software, via uploading content into the S3 bucket it created, that will ultimately to allow Amazon Engineers to SSH into the different instances utilizing their Midway Token.  To actually accomplish this there is some code added to the LaunchTemplate that exists within the Service Infrastructure stack’s Cloudformation Template.

## VPC
The VPC stack setup’s a Virtual Private Cloud (VPC) that will house the instances the Service Infrastucture Cloudformation Template will generate, as well as, various other things such as Subnets, Routing Tables, Routes, NAT Gateway, Internet Gateway, and Network ACLs.

## Bastion
The Bastion stack is part of the BONES Framework.  Generate an internet accessible instance that you can SSH to to bunny hop into private instances behind a VPC.  However, throughout DOCtor we attach EIPs on all of our instances so they are all accessible without the need to use a bastion server.  We still build this stack because it generates some necessary IAM Roles and Policies needed for the Service Infrastructure to work, however, we remove the Auto Scaling Group so the bastion’s instance do not get turned on.

## Service Infrastructure
The Service Infrastructure stack setups the direct assets that the service component need in order to successfully run.  Most of the resources it setup is specific to the service component running and could be IAM Roles and Policies, Security Groups, S3 Buckets, or even KMS.  However, some of the common shared technology among all the service components are the Auto Scaling Group, Scaling Policies, Log Groups, and Code Deploy Deployment Groups.  When an infrastructure change happens there are times where it needs replace all the instances running in all the Auto Scaling Groups.  Some examples of events that might trigger this is changes to the AMI, Launch Template, or Instance Type.  The Launch Template instructs the instance on start up what scripts it needs to execute.  These launch scripts setup other applications to run on the host, such as, Singlepass, perform security updates, and trigger code deployments.  To protect our system from a regional outage during an infrastructure change we configure the Cloudformation Template to only deploy to one Auto Scaling Group at a time.  As the Auto Scaling Group terminates an instance and brings a new back one in it’s place.  This will trigger a Code Deploy Deployment of the last revision that was successfully deployed.  To assist with the regional availability we employ a feature of Code Deploy called Sanity Tests.  This test will hold the code deploy deployment until it receives a healthy signal from the underlining service to know everything is A-OK.  Once the Sanity Test concludes with no error codes, then the Auto Scaling Group step will be completed and it will move to the next Auto Scaling Group in the mix.  Should the Cloudformation Template change or the Sanity Test fail from the Code Deploy Deployment the system will trigger an automatic rollback, reverting everything it changed one Auto Scaling Group at a time re-executing the previous versions Sanity Tests.

## Alarms
The Alarms stack set ups all the Cloud Watch Alarms and Monitors to alert us that if our service component is misbehaving.  It looks at metrics emitted by the service component and will notifiy us via Tickets with different severity based on how we configure the Alarm.  

`