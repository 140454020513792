import React, {useEffect, useState} from 'react';
import { withRouter } from 'react-router-dom';
import { SideNavigation } from '../aws-ui-components';


import { Amplify, Auth, Hub } from 'aws-amplify';
import awsconfig from "../aws-exports.js";
awsconfig.oauth.redirectSignIn = `${window.location.origin}/`;
awsconfig.oauth.redirectSignOut = `${window.location.origin}/`;
Amplify.configure(awsconfig);

// Component ServiceNavigation is the Side Navigation component that is used in BasicLayout, CreateForm, ServiceHomepage, and Table flows.
// Implement like this: <ServiceNavigation />
function ServiceNavigation(props) {
  // Amazon Federate Midway athentication
  const [user, setUser] = useState(null);
  // getUser function
  function getUser() {
    return Auth.currentAuthenticatedUser()
        .then((userData) => userData)
        .catch(() => console.log("Not signed in"));
  }

  // Use effect for auth
  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          console.log(event);
          console.log(data);
          getUser().then((userData) => setUser(userData));
          console.log(user);
          break;
        case "signOut":
          setUser(null);
          break;
        case "signIn_failure":
          console.log("Sign in failure", data);
          break;
      }
    });
    getUser().then((userData) => setUser(userData));
    if(user == null) {
    }
  }, []);

  // If the provided link is empty, do not redirect pages
  function onFollowHandler(ev) {
    ev.preventDefault();
    if (ev.detail.href) {
      props.history.push(ev.detail.href.substring(1));
    }
  }


  const items = [
    {
      type: 'link',
      text: (user?
          "Hello, " + user.attributes.given_name + "!"
          :
          ""),
      external: false,
    },
    {
      type: "divider"
    },
    // {
    //   type: 'section',
    //   text: 'Endpoints',
    //   items: [
        // { type: 'link', text: 'Push API', href: '#/endpoints/push-api' },
        // { type: 'link', text: 'Poll API', href: '#/endpoints/poll-api' },
        // { type: 'link', text: 'Cache statistics', href: '#/basic' },
        // { type: 'link', text: 'Monitoring and alarms', href: '#/table-empty' },
        // { type: 'link', text: 'Popular objects', href: '' },
        // { type: 'link', text: 'Top referrers', href: '' },
        // { type: 'link', text: 'Usage', href: '' },
        // { type: 'link', text: 'Viewers', href: '' }
      // ]
    // },
    {
      type: 'section',
      text: 'Documentation',
      items: [
        {
          type: 'expandable-link-group',
          text: 'Customer',
          items: [
            {
              type: 'link-group',
              text: 'Customer API',
              items: [
                { type: 'link', text: 'Model', href: '#/documentation/customer/controlplane/customerapi/model/' },
                { type: 'link', text: 'Java Example', href: '#/documentation/customer/controlplane/customerapi/example/' },
              ]
            },
            {
              type: 'link-group',
              text: 'Push API',
              items: [
                { type: 'link', text: 'Model', href: '#/documentation/customer/dataplane/dppushapi/model/' },
              ]
            },
            {
              type: 'link-group',
              text: 'Poll API',
              items: [
                { type: 'link', text: 'Model', href: '#/documentation/customer/dataplane/dppollapi/model/' },
              ]
            },
          ]
        },
        {
          type: 'expandable-link-group',
          text: 'Internal',
          items: [
            { type: 'link', text: 'Control Plane Design', href: '#/documentation/internal/controlplane/' },
            { type: 'link', text: 'Infrastructure', href: '#/documentation/internal/infrastructure/' },
            {
              type: 'link-group',
              text: 'Customer API',
              items: [
                { type: 'link', text: 'Model', href: '#/documentation/internal/controlplane/customerapi/model/' },
              ]
            },
            {
              type: 'link-group',
              text: 'External Control API',
              items: [
                { type: 'link', text: 'Model', href: '#/documentation/internal/controlplane/extcontrolapi/model/' },
              ]
            },
            {
              type: 'link-group',
              text: 'Internal Control API',
              items: [
                { type: 'link', text: 'Model', href: '#/documentation/internal/controlplane/intcontrolapi/model/' },
              ]
            },
            {
              type: 'link-group',
              text: 'Push API',
              items: [
                { type: 'link', text: 'Model', href: '#/documentation/internal/dataplane/dppushapi/model/' },
              ]
            },
            {
              type: 'link-group',
              text: 'Poll API',
              items: [
                { type: 'link', text: 'Model', href: '#/documentation/internal/dataplane/dppollapi/model/' },
              ]
            },
            {
              type: 'link-group',
              text: 'Publisher API',
              items: [
                { type: 'link', text: 'Model', href: '#/documentation/internal/dataplane/publisherapi/model/' },
              ]
            },
          ]
        }
      ]
    }
  ];


  return (
    <SideNavigation
      header={{ text: '(DOCtor) DNS Operational Controller', href: '#/' }}
      items={items}
      activeHref={`#${props.location.pathname}`}
      onFollow={onFollowHandler}
    />
  );
}

export default withRouter(ServiceNavigation);
