import React from 'react';
import { Route } from 'react-router-dom';

// Customer
import page_documentation_customer_controlplane_customerapi_model from './documentation/customer/controlplane/customerapi/model'
import page_documentation_customer_controlplane_customerapi_example from './documentation/customer/controlplane/customerapi/java_example'
import page_documentation_customer_dataplane_dppushapi_model from './documentation/customer/dataplane/dppushapi/model'
import page_documentation_customer_dataplane_dppollapi_model from './documentation/customer/dataplane/dppollapi/model'

// Internal
import page_documentation_internal_controlplane_design from './documentation/internal/controlplane/design'
import page_documentation_internal_infrastructure_design from './documentation/internal/infrastructure'
import page_documentation_internal_controlplane_customerapi_model from './documentation/internal/controlplane/customerapi/model'
import page_documentation_internal_controlplane_extcontrolapi_model from './documentation/internal/controlplane/extcontrolapi/model'
import page_documentation_internal_controlplane_intcontrolapi_model from './documentation/internal/controlplane/intcontrolapi/model'
import page_documentation_internal_dataplane_dppushapi_model from './documentation/internal/dataplane/dppushapi/model'
import page_documentation_internal_dataplane_dppollapi_model from './documentation/internal/dataplane/dppollapi/model'
import page_documentation_internal_dataplane_publisherapi_model from './documentation/internal/dataplane/publisherapi/model'


// demo junk
import DEMO_BobRossIntro from './demos/BobRossIntro.jsx';
import DEMO_Basic from './demos/BasicLayout.jsx';
import DEMO_ServiceHomepage from './demos/ServiceHomepage.jsx';
import DEMO_CreateForm from './demos/CreateForm.jsx';
import DEMO_TableView from './demos/Table.jsx';
import DEMO_EmptyTableView from './demos/TableWithEmptyState.jsx';

import '@amzn/awsui-global-styles/polaris.css';
// import react from "@vitejs/plugin-react";

import { useState, useEffect } from 'react';
import { Amplify, Auth, Hub } from 'aws-amplify';
import awsconfig from "./../aws-exports.js";
awsconfig.oauth.redirectSignIn = `${window.location.origin}/`;
awsconfig.oauth.redirectSignOut = `${window.location.origin}/`;
Amplify.configure(awsconfig);


function App() {
    // Amazon Federate Midway athentication
    const [user, setUser] = useState(null);
    // getUser function
    function getUser() {
        return Auth.currentAuthenticatedUser()
            .then((userData) => userData)
            .catch(() => console.log("Not signed in"));
    }

    // Use effect for auth
    useEffect(() => {
        Hub.listen("auth", ({ payload: { event, data } }) => {
            switch (event) {
                case "signIn":
                    console.log(event);
                    console.log(data);
                    getUser().then((userData) => setUser(userData));
                    console.log(user);
                    break;
                case "signOut":
                    setUser(null);
                    break;
                case "signIn_failure":
                    console.log("Sign in failure", data);
                    break;
            }
        });
        getUser().then((userData) => setUser(userData));
        if(user == null) {
        }
    }, []);
    return (
        <div>
            {user ? (
                <div>
                    <Route exact path="/" component={DEMO_ServiceHomepage}/>
                    <Route exact path="/basic" component={DEMO_Basic}/>
                    <Route exact path="/intro" component={DEMO_BobRossIntro}/>
                    <Route exact path="/create" component={DEMO_CreateForm}/>
                    <Route exact path="/endpoints/push-api" component={DEMO_TableView}/>
                    <Route exact path="/endpoints/poll-api" component={DEMO_TableView}/>
                    <Route exact path="/table-empty" component={DEMO_EmptyTableView}/>

                    {/* Customer */}
                    <Route exact path="/documentation/customer/controlplane/customerapi/model" component={page_documentation_customer_controlplane_customerapi_model}/>
                    <Route exact path="/documentation/customer/controlplane/customerapi/example" component={page_documentation_customer_controlplane_customerapi_example}/>
                    <Route exact path="/documentation/customer/dataplane/dppushapi/model" component={page_documentation_customer_dataplane_dppushapi_model}/>
                    <Route exact path="/documentation/customer/dataplane/dppollapi/model" component={page_documentation_customer_dataplane_dppollapi_model}/>

                    {/* Internal */}
                    <Route exact path="/documentation/internal/controlplane/customerapi/model" component={page_documentation_internal_controlplane_customerapi_model}/>
                    <Route exact path="/documentation/internal/controlplane/extcontrolapi/model" component={page_documentation_internal_controlplane_extcontrolapi_model}/>
                    <Route exact path="/documentation/internal/controlplane/intcontrolapi/model" component={page_documentation_internal_controlplane_intcontrolapi_model}/>
                    <Route exact path="/documentation/internal/dataplane/dppushapi/model" component={page_documentation_internal_dataplane_dppushapi_model}/>
                    <Route exact path="/documentation/internal/dataplane/dppollapi/model" component={page_documentation_internal_dataplane_dppollapi_model}/>
                    <Route exact path="/documentation/internal/infrastructure/" component={page_documentation_internal_infrastructure_design}/>
                    <Route exact path="/documentation/internal/controlplane/" component={page_documentation_internal_controlplane_design}/>
                    <Route exact path="/documentation/internal/dataplane/publisherapi/model" component={page_documentation_internal_dataplane_publisherapi_model}/>
                </div>
            ) : (
                <div>
                    <p>Not signed in</p>
                    <button onClick={() => Auth.federatedSignIn({provider: 'AmazonFederate'})}>Federate Sign In</button>
                </div>
            )}
        </div>
    );
}

export default App;
