import React from 'react';
import ServiceNavigation from '../../../../ServiceNavigation';
import {
    AppLayout,
    Button,
    ColumnLayout,
    FormField,
    Icon,
    Select,
    Container,
    Header,
    Box,
    Grid,
    SpaceBetween,
    Link
} from '../../../../../aws-ui-components';
import RenderHTML from "../../../../utils/RenderHTML";
import '../../../../../styles/servicehomepage.scss';

import model_data from "../../../../../data/model/r53drintcontrolapi.api.json"
import model_data_docs from "../../../../../data/model/r53drintcontrolapi.docs.json"

// Component ServiceHomepage is a skeleton of a service's homepage using AWS-UI React components.
export default () => {
    return (
        <AppLayout
            disableContentPaddings={true}
            navigation={<ServiceNavigation />} // Navigation panel content imported from './ServiceNavigation.jsx'
            content={<Content />}
            contentType="default"
            navigationOpen={true}
            toolsHide={true}
        />
    );
};

function filterCustomerOperations() {
    return Object.entries(model_data.operations)
        .reduce((filteredDict, [key, val]) => {
                    filteredDict[key] = val;
                    return filteredDict;
                }, {});
}

function isRequired(list, key) {
    if(list != null && list.includes(key)) {
        return <span>(required)</span>
    }
    return <span>(optional)</span>
}

function isDictionary(obj) {
    return obj instanceof Object;
}

function loadDescription(key) {
    return <RenderHTML data={model_data_docs.shapes[key].base} />
}

function renderStructure(shape, key, depth) {
    const result = []
    if(shape in model_data.shapes) {
        if (model_data.shapes[shape].type === "structure") {
            if ("members" in model_data.shapes[shape] && key in model_data.shapes[shape].members) {
                result.push(<li>
                    {key} - {isRequired(model_data.shapes[shape].required, key)} {loadDescription(model_data.shapes[shape].members[key].shape)}
                    {renderStructure(model_data.shapes[shape].members[key].shape, model_data.shapes[shape].members[key].shape, depth + 1)}
                </li>);
            } else {
                result.push(<li>
                    {key} - {isRequired(model_data.shapes[shape].required, key)}
                    {Object.entries(model_data.shapes[shape].members).map(([member_key, member_value]) => (
                        <div>{renderStructure(shape, member_key)}</div>
                    ))}
                </li>);
            }
        } else if (model_data.shapes[shape].type === "list") {
            Object.entries(model_data.shapes[shape]).map(([member_key, member_value]) => {
                if (member_key === "member") {
                    result.push(renderStructure(member_value.shape, member_value.shape, depth + 1));
                } else {
                    result.push(<li>{member_key} - {member_value != null && <span>{member_value}</span>}</li>);
                }
            });
        } else {
            Object.entries(model_data.shapes[shape]).map(([member_key, member_value]) => {
                if (isDictionary(member_value)) {
                    result.push(<ul>
                        {renderStructure(member_value.shape, shape, depth + 1)}
                    </ul>);
                } else {
                    result.push(<li>{member_key} - {member_value != null && <span>{member_value}</span>}</li>);
                }
            });
        }
    }
    return <ul>
        {result}
    </ul>
}

// The content in the main content area of the App layout
const Content = () => (
    <Box padding="s" margin={{bottom: 'l'}}>
        <h1>Internal Control API Model</h1>

        <Box padding={{top: 's'}}>
            <Grid
                gridDefinition={[
                    {colspan: {xl: 10}, offset: {xl: 1}},
                ]}
            >
                <SpaceBetween size="l">

                    {Object.entries(filterCustomerOperations()).map(([key, value]) => (
                        <div>
                            {/* HEADER */}
                            <div>
                                <Box variant="h1" tagOverride="h2">
                                    {value.name}
                                </Box>
                            </div>

                            {/* Body */}
                            <Container>
                                <Box variant="h2" tagOverride="h3">
                                    Description
                                </Box>
                                <ColumnLayout columns={1} variant="text-grid">
                                    <RenderHTML data={model_data_docs.operations[value.name]}/>
                                </ColumnLayout>
                                <hr/>
                                <ColumnLayout columns={1} variant="text-grid">
                                    {"input" in value ? (
                                        <div>
                                            <h2>Input - {value.input?.shape}</h2>
                                            <h3>Description</h3>
                                            <RenderHTML data={model_data_docs.shapes[value.input.shape].base}/>
                                            <h3>Parameters</h3>
                                            {Object.entries(model_data.shapes[value.input.shape].members).map(([member_key, member_value]) => (
                                                <div>{renderStructure(value.input.shape, member_key)}</div>
                                            ))}
                                        </div>
                                    ) : (
                                        <div>
                                            <h2>Input - None!</h2>
                                        </div>
                                    )}
                                    <div>
                                        <h2>Output - {value.output.shape}</h2>
                                        <h3>Description</h3>
                                        <RenderHTML data={model_data_docs.shapes[value.output.shape].base}/>
                                        <h3>Parameters</h3>
                                        {Object.entries(model_data.shapes[value.output.shape].members).map(([member_key, member_value]) => (
                                            <div>{renderStructure(value.output.shape, member_key)}</div>
                                        ))}
                                    </div>
                                    <div>
                                        {"errors" in value &&
                                            Object.entries(value.errors).map(([error_key, error_value]) => (
                                                <div>
                                                    <h2>Error - {error_value.shape}</h2>
                                                    <RenderHTML data={model_data_docs.shapes[error_value.shape].base}/>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </ColumnLayout>
                            </Container>

                        </div>
                    ))}
                </SpaceBetween>
            </Grid>
        </Box>
    </Box>
);
